/* https://s3.amazonaws.com/ads-videoplayer/rich-media-ads-global.css?ver=5.9.2 */

/* RICH MEDIA STYLESHEET */
/* ALL */
.gpt-ad {
  width: 100%;
  max-height: 1200px;
  text-align: center;
}

#div-gpt-out-of-page,
#div-gpt-inimage {
  max-height: unset !important;
  position: unset !important;
}

@media only screen and (max-device-width: 700px) {
  .ad-single-top {
    background-color: #fff !important;
    /* margin-top: 0 !important; */
    margin-left: 0 !important;
    margin-bottom: 20px !important;
    min-width: 320px !important;
    margin-left: 0 !important;
    border-bottom: 0 !important;
  }
}

/* ENCORE */
iframe[width='7'] {
  width: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0 auto;
  max-width: 320px;
  min-height: 50px;
  background: transparent;
}

div.ad-encore {
  height: 50px;
}

@media (max-device-width: 700px) {
  iframe[width='7'] {
    width: 100vw;
    max-width: 100vw;
    height: 100%;
    /* min-height: 50px; */
  }
  div.ad-encore {
    height: 13vh;
    width: 100vw;
    bottom: 0;
  }
}

/* ENCORE SCROLLER */
div.ad-encore-scroller {
  height: 50px;
}

@media (max-device-width: 700px) {
  div.ad-encore-scroller iframe[width='7'] {
    width: 100vw;
    max-width: 100vw;
    height: 100%;
  }
  div.ad-encore-scroller {
    height: 100%;
  }
}

/* VIDEO WALL */
iframe[width='4'] {
  width: 970px;
  height: 546px;
  position: relative;
  margin: 0 auto;
}

/* PRELUDE */
iframe[width='2'] {
  width: 100vw;
  height: 100%;
  position: absolute;
  max-width: 3000px;
  max-height: 800px;
  margin: 0 auto;
  top: 0;
  left: 50%;
  transform: translate(-50%);
}

div.ad-prelude {
  position: relative;
  height: calc(100vw * 5 / 18);
  /* padding-bottom: calc(100% * 5 / 18);
	left: calc(-50vw + 50%); */
}

.cassiuslife body.home div.ad-prelude,
.cassiuslife body[class*='category-'] div.ad-prelude,
.cassiuslife body[class*='post-'] div.ad-prelude,
.cassiuslife body[class*='media_playlist-'] div.ad-prelude {
  margin-top: 10px !important;
}

.ad-has-prelude #zox-lead-top-wrap {
  padding-bottom: calc(100% * 5 / 18);
}

.blacksportsonline div.ad-prelude {
  margin-top: 0 !important;
}

.hbcugameday .ad-prelude,
.hbcugameday .ad-prelude-premium {
  padding-bottom: inherit;
  margin-top: -15px !important;
}

@media (max-device-width: 700px) {
  div.ad-prelude {
    height: calc(100vw * 20 / 18);
    /* padding-bottom: calc(100% * 15 / 18); */
  }
  .ad-has-prelude .gpt-ad-container [first-child] {
    min-height: auto !important;
    width: 100vw !important;
    left: calc(-50vw + 50%) !important;
    position: relative !important;
    display: block !important;
  }
  body.home div.ad-prelude,
  body[class*='media_playlist-'] div.ad-prelude {
    margin-top: -20px !important;
  }
  body[class*='category-'] div.ad-prelude {
    margin-top: -30px !important;
  }
  .globalgrind body[class*='post-'] div.ad-prelude {
    left: unset !important;
  }
  .blacksportsonline div.ad-prelude,
  .blacksportsonline body.home div.ad-prelude,
  .blacksportsonline body[class*='category-'] div.ad-prelude {
    margin-top: 0 !important;
  }
  .hbcugameday body.home div.ad-prelude,
  .hbcugameday body.category div.ad-prelude,
  .hbcugameday body.single div.ad-prelude {
    margin-top: -10px !important;
  }
}

.ad-has-prelude .top-ad-block {
  max-height: unset !important;
  overflow: visible !important;
}

/* PRELUDE PREMIUM */
iframe[width='3'] {
  width: 100vw;
  height: 100%;
  position: absolute;
  max-width: 3000px;
  max-height: calc(100vw * 9 / 16);
  margin: 0 auto;
  top: 0;
  left: 50%;
  transform: translate(-50%);
}

div.ad-prelude-premium {
  width: 100vw !important;
  height: calc(100vw * 10 / 16) !important;
  /* padding-bottom: calc(100% * 9 / 16); */
  left: calc(-50vw + 50%);
  position: relative;
  margin-top: -50px;
}

.bossip .ad-prelude-premium,
.madamenoire .ad-prelude-premium {
  height: calc(100vw * 10 / 16) !important;
}

body[class*='post-'] div.ad-prelude-premium {
  margin-top: -50px;
}

body.home div.ad-prelude-premium,
body[class*='category-'] div.ad-prelude-premium,
body[class*='media_playlist-'] div.ad-prelude-premium {
  margin-top: -40px;
}

.hiphopwired body[class*='category-'] div.ad-prelude-premium {
  margin-top: -80px !important;
}

.cassiuslife body.home div.ad-prelude-premium,
.cassiuslife body[class*='category-'] div.ad-prelude-premium,
.cassiuslife body[class*='post-'] div.ad-prelude-premium,
.cassiuslife body[class*='media_playlist-'] div.ad-prelude-premium {
  margin-top: 10px !important;
}

.bossip body.home div.ad-prelude-premium,
.bossip body[class*='category-'] div.ad-prelude-premium,
.bossip body[class*='post-'] div.ad-prelude-premium,
.madamenoire body.home div.ad-prelude-premium,
.madamenoire body[class*='post-'] div.ad-prelude-premium,
.madamenoire body[class*='category-'] div.ad-prelude-premium {
  margin-top: -10px !important;
}

.blacksportsonline div.ad-prelude-premium {
  margin-top: 0 !important;
}

@media (max-device-width: 700px) {
  body.home div.ad-prelude-premium,
  body[class*='media_playlist-'] div.ad-prelude-premium {
    margin-top: -20px !important;
  }
  body[class*='category-'] div.ad-prelude-premium {
    margin-top: -30px !important;
  }
  .newsone body[class*='category-'] div.ad-prelude-premium,
  .bossip body[class*='category-'] div.ad-prelude-premium,
  .hellobeautiful body[class*='post-'] div.ad-prelude-premium {
    margin-top: -20px !important;
  }
  .bossip body.home div.ad-prelude-premium,
  .madamenoire body[class*='category-'] div.ad-prelude-premium {
    margin-top: 10px !important;
  }
  .madamenoire body.home div.ad-prelude-premium,
  .cassiuslife body[class*='post-'] div.ad-prelude-premium,
  .blacksportsonline div.ad-prelude-premium,
  .blacksportsonline body.home div.ad-prelude-premium,
  .blacksportsonline body[class*='category-'] div.ad-prelude-premium {
    margin-top: 0 !important;
  }
  .hbcugameday body.home div.ad-prelude-premium,
  .hbcugameday body.category div.ad-prelude-premium,
  .hbcugameday body.single div.ad-prelude-premium {
    margin-top: -10px !important;
  }
  div.ad-prelude-premium {
    height: calc(100vw * 10 / 9) !important;
  }
}

/* IMPACT */
div.ad-impact {
  width: 100vw !important;
  height: calc(100vw * 9 / 16);
  display: block;
  /* padding-bottom: calc(100% * 9.5 / 21); */
  left: calc(-50vw + 50%);
  position: relative;
  margin-top: -50px;
}

div.ad-impact iframe {
  position: relative !important;
}

body[class*='post-'] div.ad-IMPACT {
  margin-top: -50px;
}

body.home div.ad-impact,
body[class*='category-'] div.ad-impact,
body[class*='media_playlist-'] div.ad-impact {
  margin-top: -40px;
}

.cassiuslife body.home div.ad-impact,
.cassiuslife body[class*='category-'] div.ad-impact,
.cassiuslife body[class*='post-'] div.ad-impact,
.cassiuslife body[class*='media_playlist-'] div.ad-impact {
  margin-top: 10px !important;
}

.bossip body.home div.ad-impact,
.bossip body[class*='category-'] div.ad-impact,
.bossip body[class*='post-'] div.ad-impact,
.madamenoire body.home div.ad-impact,
.madamenoire body[class*='post-'] div.ad-impact,
.madamenoire body[class*='category-'] div.ad-impact {
  margin-top: -10px !important;
}

.hbcugameday .ad-impact,
.hbcugameday .ad-impact {
  padding-bottom: inherit;
  margin-top: -15px !important;
}

@media (max-device-width: 700px) {
  body.home div.ad-impact,
  body[class*='media_playlist-'] div.ad-impact {
    margin-top: -20px !important;
  }
  body[class*='category-'] div.ad-impact {
    margin-top: -30px !important;
  }
  .bossip body.home div.ad-impact {
    margin-top: 10px !important;
  }
  .globalgrind body[class*='post-'] div.ad-impact {
    left: unset !important;
  }
}

/* INTERLUDE */
iframe[width='5'] {
  width: 100%;
  height: 100%;
  position: absolute;
  max-width: 900px;
  max-height: 1200px;
  margin: 0 auto;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  background-color: transparent;
}

div.ad-interlude {
  width: 100%;
  height: calc(100% * 1000 / 880);
  padding-bottom: calc(100% * 1000 / 880);
  /* height: calc(100% * 5 / 18); */
  /* padding-bottom: calc(100% * 5 / 18); */
  overflow: hidden;
  position: relative;
}

div.ad-interlude-horizontal {
  width: 100%;
  height: calc(100% * 5 / 18);
  padding-bottom: calc(100% * 5 / 18);
  overflow: hidden;
  position: relative;
  margin-bottom: 20px;
}

.newsone .ad-interlude-horizontal {
  padding-bottom: calc(100% * 4.3 / 18);
}

@media only screen and (max-width: 500px) {
  div.ad-interlude-horizontal {
    width: 100%;
    padding-bottom: calc(100% * 1000 / 880) !important;
    max-height: 8000px !important;
  }
  .newsone .ad-interlude-horizontal {
    padding-bottom: calc(100% * 1000 / 880) !important;
  }
}

div.ad-interlude-static {
  width: 100%;
  padding-bottom: calc(100% * 500 / 900);
  max-height: 8000px !important;
  overflow: hidden;
  position: relative;
}

.cavsnation .divonlymobile {
  display: block !important;
  text-align: center;
}

/* VERTICAL VIDEO 300x600 */
div.ad-vertical-video {
  width: 300px;
  height: 533px;
  margin: 0 auto;
}

div.ad-vertical-video iframe[width='300'] {
  height: 533px;
}

/* SHOPPABLE INTERLUDE */
.ad-shoppable {
  position: relative;
}

/* 970x250 BILLBOARD */
.ad-billboard iframe {
  width: 970px;
  height: 250px;
}

/* REVEALER */
.ad-revealer-container {
  margin: 0 auto;
  width: 100vw;
  height: 83vh;
  display: block;
  position: inherit !important;
  left: calc(-50vw + 50%) !important;
}

.ad-revealer {
  margin: 0 auto;
  width: 100vw;
  height: 80vh;
  overflow: hidden;
  position: absolute;
  clip: rect(0, auto, auto, 0) !important;
}

.ad-revealer iframe {
  margin: 0 auto !important;
  position: fixed !important;
  top: 0 !important;
  left: inherit;
  max-width: 100vw !important;
}

.ad-revealer-container:before {
  content: 'Content continues below ad';
  font-size: 0.8rem;
  color: #555;
  position: relative;
  background-color: #f0f0f0;
  width: 100vw !important;
  display: block;
}
