@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Oskari';
    src: url(/fonts/Oskari-G2-Regular.ttf) format('truetype');
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Oskari';
    src: url(/fonts/Oskari-G2-Semibold.ttf) format('truetype');
    font-weight: 600;
    font-display: swap;
}

@font-face {
    font-family: 'Oskari';
    src: url(/fonts/Oskari-G2-Regular.ttf) format('truetype');
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Oskari';
    src: url(/fonts/Oskari-G2-Semibold.ttf) format('truetype');
    font-weight: 600;
    font-display: swap;
}

@font-face {
    font-family: 'IBMPlex-mono';
    src: url(/fonts/IBMPlexMono-Regular.ttf) format('truetype');
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Judge';
    src: url(/fonts/F37Judge-Medium.woff) format('woff');
    src: url(/fonts/F37Judge-Medium.woff2) format('woff2');
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: 'Judge';
    src: url(/fonts/f37judge_boldcompressed-webfont.woff) format('woff');
    src: url(/fonts/f37judge_boldcompressed-webfont.woff2) format('woff2');
    font-weight: bold;
}

.close-button {
    position: absolute;
    right: 20px;
    width: 20px;
    height: 20px;
    background-color: black;
    border-radius: 10px;
    font-family: Arial;
    color: white;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
}